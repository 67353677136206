<template>
  <div class="comment">
    <Row class="comment__wrapper"
         justify="start">
      <Column class="comment__avatar">
        <UserAvatar :params="{avatar: params.commenter.avatar, size: 50}"/>
      </Column>
      <Column class="comment__info">
        <Row class="comment__names">
          <span class="comment__name">{{params.commenter.name}}</span>
          <Row align="center">
            <Icon class="comment__icon"
                  xlink="answer"
                  viewport="0 0 20 20"/>
            <span class="comment__commented">{{commentedName}}</span>
          </Row>
        </Row>
        <div>
          <p class="comment__comment">{{params.comment}}</p>
        </div>
        <Row align="center">
          <Icon class="comment__icon"
                xlink="calender"
                viewport="0 0 20 20"/>
          <span
            class="comment__date">{{$moment.unix(params.created_at).format('DD MMMM YYYY')}}</span>
        </Row>
      </Column>
    </Row>
  </div>
</template>

<script>
export default {
  name: 'Comment',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    commentedName() {
      return this.$comments.find((item) => item.id === this.params.parent_id).commenter.name;
    },
  },
};
</script>

<template>
  <div class="guest-review">
    <div class="guest-review__wrapper">
      <component :is="$laptop ? 'GuestReviewDesktop' : 'GuestReviewMobile'"
                 v-on:show-form="showForm"
                 :params="params"/>
      <Comment class="guest-review__comment"
               :params="comment"
               v-for="comment in comments"
               :key="comment.id"/>
      <transition name="fade">
        <div v-if="showFormComment">
          <Divider class="guest-review__divider"/>
          <LeaveComment v-on:show-form="showForm"
                        :params="{parent_id: params.id}"/>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import GuestReviewDesktop from './GuestReviewDesktop.vue';
import GuestReviewMobile from './GuestReviewMobile.vue';
import LeaveComment from './LeaveComment.vue';
import Comment from './Comment.vue';

export default {
  name: 'GuestReview',
  data() {
    return {
      showFormComment: false,
    };
  },
  components: {
    Comment,
    LeaveComment,
    GuestReviewDesktop,
    GuestReviewMobile,
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showForm(boolean) {
      this.showFormComment = boolean;
    },
  },
  computed: {
    comments() {
      return this.$comments.filter((item) => item.parent_id === this.params.id);
    },
  },
};
</script>

<template>
  <Column class="grid-md-8 grid-lg-8"
          :full="$mobile">
    <InputComponent id="commentMessage"
                    v-model="comment"
                    :label="$t('reviews.desc')"
                    type="text"
                    maxChars="700"
                    multiline/>
    <div>
      <Button v-on:click.native="setComment"
              :disabled="!comment.length > 0"
              color="main">{{ $t('reviews.send') }}
      </Button>
    </div>
  </Column>
</template>

<script>
export default {
  name: 'LeaveComment',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      comment: '',
    };
  },
  methods: {
    setComment() {
      let string = '?';
      string += `parent_id=${this.params.parent_id}`;
      string += `&comment=${this.comment}`;
      this.$http.post(`/api/${this.$page.type}/${this.$route.params.id}/comments${string}`);
      this.comment = '';
      this.$emit('show-form', false);
    },
  },
};
</script>

<template>
  <Column>
    <Row class="guest-review__mobile-top"
         justify="between">
      <UserAvatar :params="{avatar: params.commenter.avatar, size: 50}"/>
      <div class="guest-review__mobile-name">
        <p class="guest-review__name">{{params.commenter.name}}</p>
      </div>
    </Row>
    <div class="guest-review__mobile-rating">
      <Rating class="guest-review__rating"
              :params="{id: params.id,
              type: 'comments',
              rating: params.rating,
              currentUserRating: params.currentUserRating}"/>
    </div>
    <div class="guest-review__mobile-body">
      <p class="guest-review__message">{{params.comment}}</p>
    </div>
    <Row align="center"
         class="guest-review__date">
      <Icon class="guest-review__date-icon"
            viewport="0 0 20 20"
            xlink="calender"/>
      <span class="guest-review__date-time">
            {{$moment.unix(params.created_at).format('DD MMMM YYYY')}}</span>
    </Row>
  </Column>
</template>

<script>
export default {
  name: 'GuestReviewMobile',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="guest-reviews">
    <div class="guest-reviews__wrapper">
      <Row :wrap="!$laptop"
           justify="between">
        <Column v-if="$user.logged"
                class="grid-md-4 grid-lg-4"
                :full="$mobile">
          <p v-if="$laptop"
             class="guest-reviews__title">{{ params.title }}</p>
          <div>
            <Button class="guest-reviews__button"
                    v-on:click.native="$openPopupAddComment({
                      page: $page.type,
                      title: $page.type === 'news' || $page.type === 'events' ?
                      `${$t('reviews.leave_comm')}` : `${$t('reviews.leave_rev')}`
                    })"
                    v-if="$user.id"
                    :full="$mobile"
                    color="main">{{ params.button }}
            </Button>
          </div>
        </Column>
        <Column v-if="!$user.logged"
                class="guest-reviews__unauthorized grid-sm-full grid-md-8 grid-lg-4">
          <h2 class="guest-reviews__unauthorized-title">{{ $t('reviews.rev') }}
            <br v-if="$laptop">{{ $t('reviews.guests') }}</h2>
          <div>
            <a :href="`/login/keycloak?latest_url=${window.location.host}${this.$route.fullPath}`"
               class="guest-reviews__unauthorized-link">{{ $t('reviews.auth') }}
            </a>
            <span>{{ $t('reviews.or') }} </span>
            <a :href="`/login/keycloak?latest_url=${window.location.host}${this.$route.fullPath}`"
               class="guest-reviews__unauthorized-link">{{ $t('reviews.register') }}
            </a>
            <span>{{ $t('reviews.to_leave') }}</span>
          </div>
        </Column>
        <Column v-if="$comments.length > 0"
                class="grid-md-8 grid-lg-8"
                :full="$mobile">
          <GuestReview :key="review.id"
                       :params="review"
                       v-for="review in reviews"/>
        </Column>
        <Column v-if="$comments.length === 0"
                class="grid-sm-full grid-md-8 grid-lg-8">
          <Icon class="guest-reviews__unauthorized-icon"
                viewport="0 0 77 60"
                xlink="null-comment"/>
          <span class="guest-reviews__unauthorized-text">
            {{ $t('reviews.no_rev.p') }} {{ entityFeedback }}{{ $t('reviews.no_rev.p_1') }}.
            <br> {{ $t('reviews.no_rev.p_2') }}</span>
        </Column>
      </Row>
    </div>
  </div>
</template>

<script>
import GuestReview from './GuestReview/index.vue';

export default {
  name: 'GuestReviews',
  components: { GuestReview },
  props: {
    params: {},
  },
  data() {
    return {
      review: '',
      textForEntity: {
        news: this.$i18n.t('reviews.news'),
        event: this.$i18n.t('reviews.event'),
        places: this.$i18n.t('reviews.place'),
        trips: this.$i18n.t('reviews.trip'),
      },
    };
  },
  computed: {
    entityFeedback() {
      switch (this.$trip.type) {
        case 'route':
          return this.$i18n.t('reviews.route');
        case 'excursion':
          return this.$i18n.t('reviews.excursion');
        case 'terrenkur':
          return this.$i18n.t('reviews.terrenkur');
        default:
          return '';
      }
    },
    reviews() {
      return this.$comments.filter((item) => item.parent_id === null);
    },
  },
  methods: {
    setComment() {
      this.$store.commit('OPEN_POPUP', {
        clear: true,
        width: 'auto',
        height: 'auto',
        horizontal: 'center',
        vertical: 'center',
        type: 'PopupCommentOnModeration',
      });
      this.$store.dispatch('SET_COMMENT_TO_SERVER', {
        params: {
          comment: this.review,
        },
        id: this.$route.params.id,
      });
      this.$store.dispatch('GET_COMMENTS_FROM_SERVER', {
        clear: true,
        type: this.$page.type,
        id: this.$route.params.id,
      });
    },
  },
  created() {
    this.$store.dispatch('GET_COMMENTS_FROM_SERVER', {
      clear: true,
      type: this.$page.type,
      id: this.$route.params.id,
    });
  },
};
</script>
